export const ENUM_SELECTION_MODE = Object.freeze({
  EDIT: 'edit',
  DELETE: 'delete',
  REPROCESS: 'reprocess',
  NONE: 'none'
})

export const ENUM_MODALITY = Object.freeze({
  MERCAPROMO: 'MERCAPROMO',
  MERCATRADE: 'MERCATRADE'
})

export const ENUM_ACTIONS = Object.freeze({
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE: 'update'
})

export const ENUM_STATUS = Object.freeze({
  ERROR: 'error',
  MISSING_PRODUCTS: 'missing_products',
  PENDING: 'pending',
  SUCCESS: 'success'
})

export const ENUM_FALLBACK_CHANNEL = Object.freeze({
  SMS: 'SMS'
})
